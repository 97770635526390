import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  InputGroup,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Icofont from "react-icofont";
import Homeoffers from "../common/homeoffers";
import "react-toastify/dist/ReactToastify.css";
import GlobalContext_ from "../../GlobalContext";
import PageTitle from "../common/PageTitle";
import "../common/OrderSummaryCard.css";
import { CardColors, SiteColors } from "../../constants/colorTheme";
import CheckoutItemAnlook from "./innerComponents/CheckoutItemAnlook";
import { GlobalContext } from "../../components/GlobalState";

const WhatsappOrderSummaryCard = ({
  state,
  key1,
  setKey1,
  getQty,
  VOUCHER,
  REMOVE,
  setPoints,
  Points,
  removepoints,
  ServiceList1,
  service,
  setTransactiontype,
  ServiceList,
  setShowEditModal,
  setSetDelivery,
  pageName,
  // cart,
  total,
  T,
  voucher,
  subTotal,
  scrollTotop,
  // cart,
}) => {
  const { restaurantinfo } = useContext(GlobalContext_);
  const { anlookCart, setAnlookCart } = useContext(GlobalContext);
  const params = new URLSearchParams(window.location.search);
  const [anlookOrderData, setAnlookOrderData] = useState([]);
  const [error, setError] = useState(null);

  const calculateTotal = () => {
    let total = 0;
    anlookCart.forEach((item) => {
      total += item.item_price * item.quantity;
    });
    return total;
  };

  const fetchOrderData = async () => {
    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/getReceiptPrintDataV2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orderid: params.get("orderid") }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAnlookOrderData(data.response[0].items);
      setAnlookCart(data.response[0].items);
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    fetchOrderData();
  }, []);

  const clearCart = async () => {
    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/clearCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: JSON.stringify({
            userid: params.get("userid"),
            orderId: params.get("orderid"),
          }),
        }
      );

      const data = await response.json();
      setAnlookCart([]);
      toast.success("Cart cleared successfully!");

      // Wait for 1 second (1000 milliseconds) before navigating
      setTimeout(() => {
        const mobile = params.get("mobile");
        const userid = params.get("userid");
        const orderid = params.get("orderid");
        window.location.href = `/anlook/order?mobile=${mobile}&userid=${userid}&orderid=${orderid}`;
      }, 1000); // 1000ms = 1 second
    } catch (error) {
      console.error("Error clearing cart:", error);
      toast.error("Failed to clear cart.");
    }
  };

  return (
    <div>
      {pageName === "checkout" ? (
        <div
          className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item"
          style={{ background: CardColors.primary }}
        >
          <div className="d-flex mb-4 osahan-cart-item-profile">
            <Image
              fluid
              className="mr-3 rounded-pill"
              alt={restaurantinfo?.merchant_image}
              src={restaurantinfo?.merchant_image}
              loading="lazy"
            />
            <div className="d-flex flex-column">
              <h6 className="mb-1 text-white">
                {restaurantinfo?.merchant_name}
              </h6>
              <p className="mb-0 text-white">
                <Icofont icon="location-pin" />
                {restaurantinfo?.address}
              </p>
            </div>
          </div>
          {anlookCart === null ? (
            <div className="text-center bg-white rounded shadow-sm mb-2">
              {state.empty}
            </div>
          ) : anlookCart.length > 0 ? (
            <div>
              {anlookCart?.map((cartItem, Index) => (
                <div className="bg-white rounded shadow-sm mb-2" key={Index}>
                  {cartItem.quantity > 0 ? (
                    <CheckoutItemAnlook
                    actualName={cartItem.actualName}
                    sizeWords= {cartItem.sizeWords}
                    sizeId={cartItem.size_id}
                      refresh={fetchOrderData}
                      item={cartItem}
                      itemName={cartItem.item_name} //replace with actualName
                      price={Number(cartItem.item_price)}
                      size_words={cartItem.size_words || ""}
                      discounted_price={cartItem.price}
                      vegNonvegTag= {cartItem.itemImage}
                      priceUnit="₹ "
                      id={cartItem.item_id}
                      qty={Number(cartItem.quantity)}
                      show={true}
                      minValue={0}
                      maxValue={12}
                      index={Index}
                      getValue={getQty}
                      category_id={1}
                    />
                  ) : null}
                </div>
              ))}
              <div>
                <div
                  className="btn btn-secondary btn-block btn-lg"
                  onClick={clearCart}
                >
                  Clear Cart
                </div>
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                <Link
                  to="/offers"
                  className="btn btn-block btn-lg btn-outline-success mb-2"
                >
                  Use Coupon
                  <Icofont icon="long-arrow-right" />
                </Link>
                <InputGroup className="input-group-sm mb-2">
                  {state.total?.less_voucher === 0 ? (
                    <Form.Control
                      type="text"
                      value={key1}
                      onChange={(e) => setKey1(e.target.value)}
                      placeholder="Enter promo code"
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={state.total?.voucher_name}
                      placeholder="Enter promo code"
                    />
                  )}
                  <InputGroup.Append>
                    {state.total?.less_voucher === 0 ? (
                      <Button
                        variant="success"
                        type="button"
                        id="button-addon2"
                        onClick={VOUCHER}
                      >
                        <Icofont icon="sale-discount" /> APPLY
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        type="button"
                        onClick={REMOVE}
                        id="button-addon2"
                      >
                        {" "}
                        REMOVE VOUCHER
                      </Button>
                    )}
                  </InputGroup.Append>
                </InputGroup>

                <InputGroup className="input-group-sm mb-2">
                  {state.total?.pts_redeem_amt === 0 ? (
                    <Form.Control
                      type="text"
                      onChange={(e) => setPoints(e.target.value)}
                      placeholder="Enter points"
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={state.total?.pts_redeem_amt}
                      onChange={(e) => setPoints(e.target.value)}
                      placeholder="Enter points"
                    />
                  )}
                  <InputGroup.Append>
                    {state.total?.pts_redeem_amt === 0 ? (
                      <Button
                        variant="success"
                        type="button"
                        id="button-addon2"
                        onClick={Points}
                      >
                        {" "}
                        REDEEM POINTS
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        type="button"
                        id="button-addon2"
                        onClick={removepoints}
                      >
                        {" "}
                        REMOVE POINTS
                      </Button>
                    )}
                  </InputGroup.Append>
                </InputGroup>

                <InputGroup className="mb-0"></InputGroup>
              </div>
              {state.total ? (
                <div>
                  <div className="mb-2 bg-white rounded p-2 clearfix">
                    {state.total?.less_voucher === 0 ? (
                      ""
                    ) : (
                      <div>
                        <p className="mb-1">
                          Less Voucher{" "}
                          <span className="float-right text-dark">
                            {state.total?.less_voucher}
                          </span>{" "}
                        </p>
                      </div>
                    )}
                    <p className="mb-1">
                      Item Total{" "}
                      <span className="float-right text-dark">
                        <span className="text-danger">₹{calculateTotal()}</span>
                      </span>
                    </p>
                    <p className="mb-1">
                      Restaurant Charges{" "}
                      <span className="float-right text-dark">
                        ₹
                        {state.total.taxable_total
                          ? Math.round(state.total.taxable_total)
                          : 0}
                      </span>
                    </p>
                    <p className="mb-1">
                      Points Redeemed{" "}
                      <span className="float-right text-dark">
                        {state.total?.pts_redeem_amt || 0}
                      </span>
                    </p>
                    <p className="mb-1">
                      Delivery Fee
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            Total discount breakup
                          </Tooltip>
                        }
                      >
                        <span className="text-info ml-1">
                          <Icofont icon="info-circle" />
                        </span>
                      </OverlayTrigger>
                      <span className="float-right text-dark">
                        {/* ₹{state.total.delivery_charges || 0} */}
                        ₹50
                      </span>
                    </p>
                    <p className="mb-1 text-success">
                      Total Discount
                      <span className="float-right text-success">
                        ₹{state.total.total_discount || 0}
                      </span>
                    </p>
                    <hr />
                    <h6 className="font-weight-bold mb-0">
                      TO PAY{" "}
                      <span className="float-right">₹{calculateTotal()}</span>
                    </h6>
                  </div>
                  {state.Pay.transaction_type === "Delivery" ? (
                    state.count.street ? (
                      <div className="mb-2 bg-white rounded p-2 clearfix">
                        Deliver here:<br></br>
                        <p style={{ color: "black" }}>
                          {state.count.street} {state.count.city}{" "}
                          {state.count.state} {state.count.zipcode}
                        </p>
                        <Link
                          onClick={() => setShowEditModal(true)}
                          className="btn btn-primary btn-block "
                        >
                          Change address
                        </Link>
                      </div>
                    ) : (
                      <Link
                        onClick={() => setSetDelivery(true)}
                        className="btn btn-primary btn-block btn-lg"
                      >
                        Set Delivery Address
                      </Link>
                    )
                  ) : (
                    ""
                  )}
                  <div className="pt-2"></div>
                  <div className="alert alert-success" role="alert">
                    You have saved <strong>{state.total.total_discount}</strong>{" "}
                    on the bill
                  </div>
                  <div className="pt-2"></div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div className="text-center bg-white rounded shadow-sm mb-2">
              {state.empty}
            </div>
          )}
        </div>
      ) : (
        //   _________________________home_page______________________________________________________
        <div>
          {voucher.free_delivery ? (
            <PageTitle
              title={voucher.title || ""}
              subTitle={voucher.free_delivery}
            />
          ) : (
            " "
          )}
          <div className="">
            {voucher.voucher?.map((Voucher) => (
              <Homeoffers
                vouchertitle={Voucher.voucher_title}
                voucherdescription={Voucher.voucher_description}
                Discount={Voucher.Discount}
                vouchername={Voucher?.voucher_name}
              />
            ))}
          </div>
          {state.cart === null ? (
            <div className="text-center bg-white rounded shadow-sm mb-2">
              {state.empty}
            </div>
          ) : state.cart.length > 0 ? (
            <div
              className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item"
              style={{ background: CardColors.primary }}
            >
              <div>
                <h5 className="mb-1 text-white">Your Order</h5>
                {state.count === null ? null : (
                  <p className="mb-4 text-white">
                    {state.count.cart_count} Items
                  </p>
                )}
                {anlookCart?.map((cartItem, Index) => (
                  <div className="bg-white rounded shadow-sm mb-2" key={Index}>
                    {T ? (
                       <CheckoutItemAnlook
                       sizeId={cartItem.size_id}
                       actualName={cartItem.actualName}
                       sizeWords= {cartItem.sizeWords}
                         refresh={fetchOrderData}
                         item={cartItem}
                         itemName={cartItem.item_name} //replace with actualName
                         price={Number(cartItem.item_price)}
                         size_words={cartItem.size_words || ""}
                         discounted_price={cartItem.price}
                         vegNonvegTag= {cartItem.itemImage}
                         priceUnit="₹ "
                         id={cartItem.item_id}
                         qty={Number(cartItem.quantity)}
                         show={true}
                         minValue={0}
                         maxValue={12}
                         index={Index}
                         getValue={getQty}
                         category_id={1}
                       />
                    ) : (
                      <CheckoutItemAnlook
                      sizeId={cartItem.size_id}
                      actualName={cartItem.actualName}
                      sizeWords= {cartItem.sizeWords}
                        refresh={fetchOrderData}
                        item={cartItem}
                        itemName={cartItem.item_name} //replace with actualName
                        price={Number(cartItem.item_price)}
                        size_words={cartItem.size_words || ""}
                        discounted_price={cartItem.price}
                        vegNonvegTag= {cartItem.itemImage}
                        priceUnit="₹ "
                        id={cartItem.item_id}
                        qty={Number(cartItem.quantity)}
                        show={true}
                        minValue={0}
                        maxValue={12}
                        index={Index}
                        getValue={getQty}
                        category_id={1}
                      />
                    )}
                  </div>
                ))}
                <div>
                  <Link
                    className="btn btn-secondary btn-block btn-lg"
                    onClick={clearCart}
                  >
                    Clear Cart
                  </Link>
                </div>
                <div className="mb-2 bg-white rounded p-2 clearfix">
                  <Image
                    fluid
                    className="float-left"
                    src="/img/wallet-icon.png"
                    alt="wallet icon"
                    loading="lazy"
                  />

                  <div>
                    {T ? (
                      <h6
                        className="font-weight-bold text-right mb-2"
                        style={{ color: CardColors.textPrimary }}
                      >
                        Subtotal :{" "}
                        <span>
                          ₹{Math.round(Math.abs(state.total?.subtotal))}
                        </span>
                      </h6>
                    ) : (
                      <h6
                        className="font-weight-bold text-right mb-2"
                        style={{ color: CardColors.textPrimary }}
                      >
                        Subtotal : <span>₹{subTotal(anlookCart)}</span>
                      </h6>
                    )}
                    {T ? (
                      <p className="text-black mb-0 text-right">
                        Less Voucher : {state.total?.less_voucher}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {!T ? (
                  <Link
                    to="/MobileVerification"
                    style={{ background: CardColors.buttonPrimary }}
                    className="btn btn-block btn-lg text-white"
                    onClick={scrollTotop}
                  >
                    Checkout
                    <Icofont icon="long-arrow-right" />
                  </Link>
                ) : (
                  <Link
                    to="/checkout"
                    style={{ background: CardColors.buttonPrimary }}
                    className="btn btn-block btn-lg text-white"
                    onClick={scrollTotop}
                  >
                    Checkout
                    <Icofont icon="long-arrow-right" />
                  </Link>
                )}
                <div className="pt-2"></div>
              </div>
              <div className="text-center bg-white rounded shadow-sm mb-2">
                {state.empty}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(WhatsappOrderSummaryCard);
